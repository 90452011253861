<template>
  <v-card>
    <v-list>
      <v-subheader>Student Links</v-subheader>
      <template v-for="(link, index) in links">
        <v-list-item v-if="link.link" :key="'link-' + index" :to="link.link">
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="link.subtitle">{{ link.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="link.href" :key="'link-href-' + index" :href="link.href" target="_blank">
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="link.subtitle">{{ link.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import { courseList } from '@/components/student/capstone'

export default {
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const links = ref([])

    onMounted(async () => {
      if (user.value.roles.includes('Employee')) {
        links.value = [
          {
            link: '/student/study-abroad/admin',
            title: 'Study Abroad Application',
            subtitle: 'View/Approve study-abroad applications'
          },
          {
            link: '/student/schedule',
            title: 'Student Schedules',
            subtitle: 'View student schedules'
          },
          {
            link: '/student/schedule/admin',
            title: 'Schedule Changes',
            subtitle: 'View pending schedule changes'
          },
          {
            link: '/student/program-change/admin',
            title: 'Program/Adivsor Changes',
            subtitle: 'View pending program or advisor changes'
          }
        ]
      } else {
        links.value = [
          {
            link: '/student/study-abroad',
            title: 'Study Abroad Application',
            subtitle: 'Start or continue a study-abroad application'
          },
          {
            link: '/student/schedule',
            title: 'View/Modify Class Schedule',
            subtitle: 'View or modify your class schedule for a recent (view-only), current, or upcoming term'
          },
          {
            link: '/student/program-change',
            title: 'Academic Program/Advisor Change',
            subtitle: 'Change your major(s), minor(s), concentration(s), certificate(s), and/or advisor'
          }
        ]
        const dt = new Date()
        root.$feathers.service('system/term').find({ query: { 'housing.start': { $lte: dt }, 'housing.end': { $gte: dt } } }).then(({ data }) => {
          if (data.length > 0) {
            links.value.unshift({ link: '/student/housing', title: 'Student Housing', subtitle: 'Apply for student housing for ' + data[0].label })
          }
        })
        await checkCapstone()
        root.$feathers.service('system/term').find({ query: { 'confirmation.start': { $lte: dt }, 'confirmation.end': { $gte: dt } } }).then(({ data }) => {
          if (data.length > 0) {
            let terms = []
            data.forEach(({ term, label }) => {
              terms.push(term)
            })
            root.$feathers.service('student/confirmation').find({ query: { pidm: user.value.pidm, term: { $in: terms } } }).then(({ total, data }) => {
              if (total > 0) {
                let row = data[0]
                if (!('holds' in row) || !Array.isArray(row.holds) || row.holds.length === 0) {
                  let incomplete = 10
                  if ('checklist' in row && row.checklist.length > 0) {
                    incomplete = 0
                    row.checklist.forEach(({ name, submitted }) => {
                      if (submitted != null) incomplete++
                    })
                  }
                  if (incomplete > 0) {
                    links.value.unshift({
                      link: '/student/checklist',
                      title: 'Access Enrollment Confirmation Checklist',
                      subtitle: 'Must be completed at the beginning of every semester.'
                    })
                  }
                }
              }
            })
          }
        })
      }
    })

    async function checkCapstone () {
      const aggregate = [
        { $match: { pidm: user.value.pidm } },
        { $unwind: '$schedule' },
        { $project: {
          term: 1,
          major: '$academics.major',
          course: {
            $substr: ['$schedule.title', 0, 6]
          },
          year: { $cond: { if: { $gt: [{ $month: '$academics.expGradDate' }, 7] }, then: { $add: [{ $year: '$academics.expGradDate' }, 1] }, else: { $year: '$academics.expGradDate' } } }
        } },
        { $match: {
          course: {
            $in: courseList
          }
        } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      if (data.length > 0) {
        links.value.unshift({
          link: '/student/capstone',
          title: 'Capstone Resources',
          subtitle: 'Access the Capstone Archive, upload your Capstone, or reserve a carrel in the library'
        })
      }
    }

    return {
      user,
      links
    }
  }
}
</script>
